<template>
  <div>
    <!-- Título principal -->
    <div class="d-flex mb-10 my-8 flex-wrap sticky-card py-8" width="100%">
      <v-btn icon rounded @click="$router.go(-1)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dense v-bind="attrs" v-on="on">
              <v-icon size="28">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Volver</span>
        </v-tooltip>
      </v-btn>
      <div class="d-flex">
        <h3 class="mt-2" v-if="cliente.nombre">
          {{ cliente.fichan }} | {{ cliente.nombre }}
        </h3>
        <v-tooltip bottom>
          <!-- Botón buscar -->
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-3" color="primary" v-bind="attrs" v-on="on"
              @click="activeShowSearchClienteUpdate = true">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Buscar clientes</span>
        </v-tooltip>
      </div>
      <v-spacer></v-spacer>
      <h1 class="justify-center mr-5">Clientes</h1>
      <v-spacer></v-spacer>
      <!-- Acciones a la derecha del título -->
      <div class="actions_title">
        <!-- Vencimientos futuros -->
        <v-btn color="primary" class="mr-2 text-capitalize rounded-lg" @click="btnActiveCreateCliente">Crear</v-btn>
      </div>
    </div>
    <!-- Información respecto al uso de la página -->
    <v-alert colored-border type="info" elevation="2" class="my-8 ma-2">
      Aquí puedes realizar el registro de mascotas junto con su propietario,
      además puedes buscar propietarios existentes.
    </v-alert>
    <!-- Contenedor principal -->
    <div v-if="licen.Var0 == 1">
      <!-- active buscar cliente para editar -->
      <v-row v-if="activeShowSearchClienteUpdate" class="ma-0 pa-1 mb-2">
        <v-card width="100%" class="pa-3 mb-5 rounded-xl">
          <v-card-title class="mt-4 ml-4">
            <h2>Buscar cliente para ver o actualizar</h2>
          </v-card-title>
          <v-spacer></v-spacer>
          <div class="pa-3">
            <div class="d-flex">
              <v-text-field v-model="searchClienteUpdate" append-icon="mdi-magnify"
                placeholder="Para buscar por nombre, rut o ficha, debe presionar enter o presionar el boton buscar al final de esté campo"
                single-line :rules="[rules.required]" @keyup.enter="btnSearchCliente" hide-details
                class="mb-1"></v-text-field>
              <v-btn color="primary" outlined class="mt-2 ml-2" @click="btnSearchCliente">Buscar</v-btn>
            </div>
            <v-data-table :headers="headersClienteUpdate" :footer-props="{
        'items-per-page-text': 'Filas por página',
      }" :items="clientes" :search="searchClienteUpdate" :loading="loadingClientes" loading-text="Buscando... Espere"
              dense>
              <template v-slot:no-data>
                <v-alert :value="true"> No hay resultados disponibles </v-alert>
              </template>
              <template v-slot:item.action="{ item }">
                <div class="actions pa-1">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs" v-on="on" class="text-capitalize rounded-lg"
                        @click="btnSelectCustomerUpdate(item.Id)"><v-icon>mdi-check-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Seleccionar</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-row>

      <!-- ver para crear nuevo -->
      <div v-if="activeShowCreateCliente || activeShowViewCliente">
        <div v-if="activeShowCreateCliente">
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row class="mt-2">
              <!-- Sección mascota -->
              <v-col cols="12" md="6" xl="6" lg="6">
                <div class="d-flex justify-center mb-5" width="100%">
                  <h2>Registro de mascota</h2>
                </div>
                <!-- Formulario mascota -->
                <div>
                  <!-- Primera fila -->
                  <div class="d-flex">
                    <v-text-field v-model="cliente.nombre" class="ma-2" outlined dense :rules="[rules.required]"
                      :validate-on-blur="false" :error="errorForm" @keyup.enter="btnCreateCliente" @change="resetErrors"
                      label="Nombre"></v-text-field>
                  </div>
                  <!-- Segunda fila -->
                  <div class="
                      d-flex
                      flex-column
                      flex-sm-column
                      flex-md-row
                      flex-lg-row
                      flex-xl-row
                    ">
                    <v-col class="pa-0 ma-0">
                      <v-select v-model="cliente.sexo" class="ma-2" :rules="[rules.required]" :validate-on-blur="false"
                        :error="errorForm" @keyup.enter="btnCreateCliente" @change="resetErrors" required
                        :items="genders" label="Género" outlined dense></v-select>
                    </v-col>
                    <!-- Fecha de nacimiento -->
                    <v-col class="pa-0 ma-0">
                      <v-menu ref="menu_pet_birth" v-model="menu_pet_birth" :close-on-content-click="false"
                        :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="cliente.nacimiento" outlined label="Fecha de nacimiento"
                            prepend-icon="mdi-calendar" readonly :rules="[rules.required]" class="ma-2" v-bind="attrs"
                            v-on="on" dense></v-text-field>
                        </template>
                        <v-date-picker locale="es-ES" v-model="cliente.nacimiento" no-title :rules="[rules.required]"
                          scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" class="text-capitalize" @click="menu_pet_birth = false">
                            Cancelar
                          </v-btn>
                          <v-btn text color="primary" @click="
        $refs.menu_pet_birth.save(cliente.nacimiento)
        ">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </div>
                  <!-- Tercera fila -->
                  <div class="
                      d-flex
                      flex-column
                      flex-sm-column
                      flex-md-row
                      flex-lg-row
                      flex-xl-row
                    ">
                    <v-autocomplete v-model="cliente.esp" class="ma-2" :items="especies" item-text="Nombre"
                      item-value="Nombre" outlined :rules="[rules.required]" dense label="Especie"></v-autocomplete>
                    <v-autocomplete v-model="cliente.raza" class="ma-2" :items="razas" item-text="Nombre"
                      item-value="Nombre" outlined :rules="[rules.required]" dense label="Raza"></v-autocomplete>
                    <v-text-field v-model="cliente.color" class="ma-2" :rules="[rules.required]" outlined dense
                      label="Color"></v-text-field>
                  </div>
                  <v-divider class="mt-2"></v-divider>
                  <!-- Microchip -->
                  <v-alert border="left" v-if="errorMicrochip" colored-border type="error" elevation="2" class="ma-2">
                    Error, el chip debe tener 15 dígitos
                  </v-alert>
                  <div>
                    <h3 class="mt-5 ma-2">Información del Microchip</h3>
                    <div class="
                        ma-0
                        mt-2
                        pa-0
                        d-flex
                        flex-column
                        flex-sm-column
                        flex-md-row
                        flex-lg-row
                        flex-xl-row
                      ">
                      <v-text-field v-model="microchip" outlined dense type="number" class="ma-2"
                        label="Número de chip">
                      </v-text-field>
                      <div class="d-flex justify-end ml-1">
                        <v-btn :disabled="!chipValid" outlined color="primary"
                          class="mb-8 ma-2 text-capitalize rounded-lg">Registrar chip</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <!-- División vista escritorio -->
              <v-divider vertical class="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex"></v-divider>
              <!-- Sección cliente -->
              <v-col cols="12" md="6" xl="6" lg="6">
                <!-- División vista móvil -->
                <v-divider class="d-flex d-sm-flex d-md-none d-lg-none d-xl-none mb-8"></v-divider>
                <!-- Título de la sección -->
                <div class="d-flex justify-center mb-5">
                  <h2>Registro de propietario</h2>
                </div>
                <!-- Cuerpo de la sección -->
                <div>
                  <!-- Buscar cliente existente -->
                  <div class="ma-2">
                    <v-checkbox v-model="activeSearchCustomers" label="Buscar cliente existente"></v-checkbox>
                  </div>
                  <!-- Tabla de clientes existentes -->
                  <v-row v-if="activeSearchCustomers" class="ma-0 pa-1 mb-2">
                    <v-card width="100%" class="pa-3 mb-5 rounded-xl">
                      <v-card-title class="mt-4 ml-4">
                        <h2>Buscar cliente</h2>
                      </v-card-title>
                      <v-spacer></v-spacer>
                      <div class="pa-3">
                        <div class="d-flex">
                          <v-text-field v-model="searchClienteUpdate" append-icon="mdi-magnify"
                            placeholder="Para buscar por nombre, rut o teléfono, debe presionar enter o presionar el boton buscar al final de esté campo"
                            single-line :rules="[rules.required]" @keyup.enter="btnSearchCliente" hide-details
                            class="mb-1"></v-text-field>
                          <v-btn color="primary" outlined class="mt-2 ml-2" @click="btnSearchCliente">Buscar</v-btn>
                        </div>
                        <v-data-table :headers="headers" :footer-props="{
        'items-per-page-text': 'Filas por página',
      }" :items="clientes" :search="search" :loading="loadingClientes" loading-text="Buscando... Espere" dense>
                          <template v-slot:no-data>
                            <v-alert :value="true">
                              No hay resultados disponibles
                            </v-alert>
                          </template>
                          <template v-slot:item.action="{ item }">
                            <div class="actions pa-1">
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn color="primary" icon v-bind="attrs" v-on="on"
                                    class="text-capitalize rounded-lg"
                                    @click="btnSelectCustomerDialog(item.Id)"><v-icon>mdi-check-circle-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Seleccionar</span>
                              </v-tooltip>
                            </div>
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                  </v-row>
                  <v-row>
                    <v-col cols="12" xs="12" sm="11" md="5" lg="2" xl="2">
                      <v-dialog v-model="dialogSelect" max-width="600px" class="ma-3" persistent>
                        <v-card class="rounded-xl">
                          <div class="pa-3">
                            <v-card-title class="title-1 text--center">¿Seguro(a) que quiere <br />
                              asignar al cliente {{ cliente.amo }}
                              <br />
                              RUT:
                              {{ cliente.rut }} <br />
                              a la nueva mascota: ?
                              {{ cliente.nombre }}
                            </v-card-title>
                            <v-card-actions class="d-flex justify-end">
                              <v-btn color="primary" outlined @click="closeDelete"
                                class="text-capitalize rounded-lg">Cancelar</v-btn>
                              <v-btn color="primary" @click="btnSelectCustomer()"
                                class="text-capitalize rounded-lg ml-3">Confirmar</v-btn>
                            </v-card-actions>
                          </div>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <!-- Formulario de registro -->
                  <div>
                    <!-- Rut cliente -->
                    <div>
                      <v-alert class="ma-2" v-if="error != ''" border="left" colored-border type="error" elevation="2">
                        {{ error }}
                      </v-alert>
                      <v-text-field v-model="rut" class="ma-2" name="rut" ref="rut" id="rut" :rules="[rules.required]"
                        :validate-on-blur="false" :error="errorForm" @keyup.enter="btnCreateCliente"
                        @change="resetErrors" type="text" @input="validateRut" outlined dense label="Rut" required
                        autofocus />
                    </div>
                    <div>
                      <v-text-field v-model="cliente.amo" class="ma-2" outlined :rules="[rules.required]"
                        :validate-on-blur="false" :error="errorForm" dense label="Nombre y apellido"></v-text-field>
                    </div>
                    <div class="
                        d-flex
                        flex-column
                        flex-sm-column
                        flex-md-row
                        flex-lg-row
                        flex-xl-row
                      ">
                      <v-menu ref="menu" v-model="menu_customer_register" :close-on-content-click="false"
                        :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="cliente.clinica" outlined label="Fecha de ingreso" readonly dense
                            class="ma-2" v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker locale="es-ES" v-model="cliente.clinica" no-title scrollable
                          event-color="primary">
                          <v-row class="d-flex justify-end pa-2">
                            <v-btn text color="primary" @click="menu = false" class="text-capitalize">
                              Cancelar
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(cliente.clinica)"
                              class="text-capitalize">
                              Ok
                            </v-btn>
                          </v-row>
                        </v-date-picker>
                      </v-menu>
                      <v-select :items="status_clientes" label="Estado del cliente" outlined dense class="ma-2">
                      </v-select>
                    </div>
                    <div class="
                        d-flex
                        flex-column
                        flex-sm-column
                        flex-md-row
                        flex-lg-row
                        flex-xl-row
                      ">
                      <v-text-field v-model="cliente.direccion" class="ma-2" outlined dense :rules="[rules.required]"
                        label="Dirección" full-width>
                      </v-text-field>
                      <div class="
                          d-flex
                          flex-column
                          flex-sm-column
                          flex-md-row
                          flex-lg-row
                          flex-xl-row
                        ">
                        <v-text-field v-model="cliente.comuna" class="ma-2" outlined dense :rules="[rules.required]"
                          full-width label="Comuna">
                        </v-text-field>
                        <v-text-field v-model="cliente.ciudad" class="ma-2" outlined dense :rules="[rules.required]"
                          full-width label="Ciudad">
                        </v-text-field>
                      </div>
                    </div>
                    <div class="
                        d-flex
                        flex-column
                        flex-sm-column
                        flex-md-row
                        flex-lg-row
                        flex-xl-row
                      ">
                      <v-text-field v-model="cliente.fono" class="ma-2" outlined dense label="Teléfono"></v-text-field>
                      <v-text-field v-model="cliente.altura" class="ma-2" outlined dense
                        label="Número celular"></v-text-field>
                    </div>
                    <div class="
                        d-flex
                        flex-column
                        flex-sm-column
                        flex-md-row
                        flex-lg-row
                        flex-xl-row
                      ">
                      <v-text-field v-model="cliente.email" class="ma-2" outlined dense label="Correo electrónico">
                      </v-text-field>
                      <v-autocomplete v-model="cliente.edad" :items="planes" item-text="Descripcion"
                        item-value="Descripcion" class="ma-2" outlined dense label="Plan de salud mascota">
                      </v-autocomplete>
                    </div>
                    <v-textarea v-model="cliente.fichaant" class="ma-2" outlined dense label="Información adicional">
                    </v-textarea>
                    <div>
                      <v-checkbox class="mx-2" v-model="cliente_actualizado" label="Datos actualizados">
                      </v-checkbox>
                    </div>
                    <!-- Botón acción crear cliente -->
                    <div class="d-flex ma-2 justify-end" width="100%">
                      <v-btn color="primary" :disabled="activeUploadingCreateCustomer"
                        :loading="activeUploadingCreateCustomer" class="text-capitalize rounded-lg"
                        @click="btnCreateCliente">Grabar</v-btn>
                      <v-btn color="primary" outlined class="text-capitalize rounded-lg ml-2"
                        @click="cleanFormCliente">Limpiar</v-btn>
                      <!-- <v-btn
                    color="primary"
                    outlined
                    class="text-capitalize rounded-lg ml-2"
                    @click=""
                    >Actualizar </v-btn
                  > -->
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <!-- Confirmacion creación de cliente -->
          <v-snackbar color="primary" v-model="successCreateCliente" multiLine>
            Cliente creado con éxito
          </v-snackbar>
        </div>
        <!-- ver para actualizar -->
        <div v-if="activeShowViewCliente">
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row class="mt-2" ref="cardclienteupdate">
              <!-- Sección mascota -->
              <v-col cols="12" md="6" xl="6" lg="6">
                <div class="d-flex justify-center mb-5" width="100%">
                  <h2>Mascota</h2>
                  <h2 class="primary--text ml-2">{{ cliente.nombre }}</h2>
                </div>
                <!-- Formulario mascota -->
                <div>
                  <!-- Primera fila -->
                  <div class="d-flex">
                    <v-text-field v-model="cliente.nombre" class="ma-2" outlined dense :rules="[rules.required]"
                      :validate-on-blur="false" :error="errorForm" @keyup.enter="btnCreateCliente" @change="resetErrors"
                      label="Nombre"></v-text-field>
                  </div>
                  <!-- Segunda fila -->
                  <div class="
                      d-flex
                      flex-column
                      flex-sm-column
                      flex-md-row
                      flex-lg-row
                      flex-xl-row
                    ">
                    <v-col class="pa-0 ma-0">
                      <v-select v-model="cliente.sexo" class="ma-2" :rules="[rules.required]" :validate-on-blur="false"
                        :error="errorForm" @keyup.enter="btnCreateCliente" @change="resetErrors" required
                        :items="genders" label="Género" outlined dense></v-select>
                    </v-col>
                    <!-- Fecha de nacimiento -->
                    <v-col class="pa-0 ma-0">
                      <v-menu ref="menu_pet_birth" v-model="menu_pet_birth" :close-on-content-click="false"
                        :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="cliente.nacimiento" outlined label="Fecha de nacimiento"
                            prepend-icon="mdi-calendar" readonly :rules="[rules.required]" class="ma-2" v-bind="attrs"
                            v-on="on" dense></v-text-field>
                        </template>
                        <v-date-picker locale="es-ES" v-model="cliente.nacimiento" no-title :rules="[rules.required]"
                          scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" class="text-capitalize" @click="menu_pet_birth = false">
                            Cancelar
                          </v-btn>
                          <v-btn text color="primary" @click="
        $refs.menu_pet_birth.save(cliente.nacimiento)
        ">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </div>
                  <!-- Tercera fila -->
                  <div class="
                      d-flex
                      flex-column
                      flex-sm-column
                      flex-md-row
                      flex-lg-row
                      flex-xl-row
                    ">
                    <v-autocomplete v-model="cliente.esp" class="ma-2" :items="especies" item-text="Nombre"
                      item-value="Nombre" outlined :rules="[rules.required]" dense label="Especie"></v-autocomplete>
                    <v-autocomplete v-model="cliente.raza" class="ma-2" :items="razas" item-text="Nombre"
                      item-value="Nombre" outlined :rules="[rules.required]" dense label="Raza"></v-autocomplete>
                    <v-text-field v-model="cliente.color" class="ma-2" :rules="[rules.required]" outlined dense
                      label="Color"></v-text-field>
                  </div>
                  <v-divider class="mt-2"></v-divider>
                  <!-- Microchip -->
                  <v-alert border="left" v-if="errorMicrochip" colored-border type="error" elevation="2" class="ma-2">
                    Error, el chip debe tener 15 dígitos
                  </v-alert>
                  <div>
                    <h3 class="mt-5 ma-2">Información del Microchip</h3>
                    <div class="
                        ma-0
                        mt-2
                        pa-0
                        d-flex
                        flex-column
                        flex-sm-column
                        flex-md-row
                        flex-lg-row
                        flex-xl-row
                      ">
                      <v-text-field v-model="microchip" outlined dense type="number" class="ma-2"
                        label="Número de chip">
                      </v-text-field>
                      <div class="d-flex justify-end ml-1">
                        <v-btn :disabled="!chipValid" outlined color="primary"
                          class="mb-8 ma-2 text-capitalize rounded-lg">Registrar chip</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <!-- División vista escritorio -->
              <v-divider vertical class="d-none d-sm-none d-md-flex d-lg-flex d-xl-flex"></v-divider>
              <!-- Sección cliente -->
              <v-col cols="12" md="6" xl="6" lg="6">
                <!-- División vista móvil -->
                <v-divider class="d-flex d-sm-flex d-md-none d-lg-none d-xl-none mb-8"></v-divider>
                <!-- Título de la sección -->
                <div class="d-flex justify-center mb-5">
                  <h2>Propietario</h2>
                  <h2 v-if="cliente.amo" class="primary--text ml-2">
                    {{ cliente.amo }}
                  </h2>
                </div>
                <!-- Cuerpo de la sección -->
                <div>
                  <v-row>
                    <v-col cols="12" xs="12" sm="11" md="5" lg="2" xl="2">
                      <v-dialog v-model="dialogSelect" max-width="600px" class="ma-3" persistent>
                        <v-card class="rounded-xl">
                          <div class="pa-3">
                            <v-card-title class="title-1 text--center">¿Seguro(a) que quiere <br />
                              asignar al cliente {{ cliente.amo }}
                              <br />
                              RUT:
                              {{ cliente.rut }} <br />
                              a la nueva mascota: ?
                              {{ cliente.nombre }}
                            </v-card-title>
                            <v-card-actions class="d-flex justify-end">
                              <v-btn color="primary" outlined @click="closeDelete"
                                class="text-capitalize rounded-lg">Cancelar</v-btn>
                              <v-btn color="primary" @click="btnSelectCustomer()"
                                class="text-capitalize rounded-lg ml-3">Confirmar</v-btn>
                            </v-card-actions>
                          </div>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <!-- Formulario de actualizacion -->
                  <div>
                    <!-- Rut cliente -->
                    <div>
                      <v-alert class="ma-2" v-if="error != ''" border="left" colored-border type="error" elevation="2">
                        {{ error }}
                      </v-alert>
                      <v-text-field v-model="rut" class="ma-2" name="rut" ref="rut" id="rut" :rules="[rules.required]"
                        :validate-on-blur="false" :error="errorForm" @keyup.enter="btnCreateCliente"
                        @change="resetErrors" type="text" @input="validateRut" outlined dense label="Rut" readonly
                        autofocus />
                    </div>
                    <div>
                      <v-text-field v-model="cliente.amo" class="ma-2" outlined :rules="[rules.required]"
                        :validate-on-blur="false" :error="errorForm" dense label="Nombre y apellido"></v-text-field>
                    </div>
                    <div class="
                        d-flex
                        flex-column
                        flex-sm-column
                        flex-md-row
                        flex-lg-row
                        flex-xl-row
                      ">
                      <v-menu ref="menu" v-model="menu_customer_register" :close-on-content-click="false"
                        :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="cliente.clinica" outlined label="Fecha de ingreso" readonly dense
                            class="ma-2" v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker locale="es-ES" v-model="cliente.clinica" no-title scrollable
                          event-color="primary">
                          <v-row class="d-flex justify-end pa-2">
                            <v-btn text color="primary" @click="menu = false" class="text-capitalize">
                              Cancelar
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(cliente.clinica)"
                              class="text-capitalize">
                              Ok
                            </v-btn>
                          </v-row>
                        </v-date-picker>
                      </v-menu>
                      <v-select :items="status_clientes" label="Estado del cliente" outlined dense class="ma-2">
                      </v-select>
                    </div>
                    <div class="
                        d-flex
                        flex-column
                        flex-sm-column
                        flex-md-row
                        flex-lg-row
                        flex-xl-row
                      ">
                      <v-text-field v-model="cliente.direccion" class="ma-2" outlined dense :rules="[rules.required]"
                        label="Dirección" full-width>
                      </v-text-field>
                      <div class="
                          d-flex
                          flex-column
                          flex-sm-column
                          flex-md-row
                          flex-lg-row
                          flex-xl-row
                        ">
                        <v-text-field v-model="cliente.comuna" class="ma-2" outlined dense :rules="[rules.required]"
                          full-width label="Comuna">
                        </v-text-field>
                        <v-text-field v-model="cliente.ciudad" class="ma-2" outlined dense :rules="[rules.required]"
                          full-width label="Ciudad">
                        </v-text-field>
                      </div>
                    </div>
                    <div class="
                        d-flex
                        flex-column
                        flex-sm-column
                        flex-md-row
                        flex-lg-row
                        flex-xl-row
                      ">
                      <v-text-field v-model="cliente.fono" class="ma-2" outlined dense label="Teléfono"></v-text-field>
                      <v-text-field v-model="cliente.altura" class="ma-2" outlined dense
                        label="Número celular"></v-text-field>
                    </div>
                    <div class="
                        d-flex
                        flex-column
                        flex-sm-column
                        flex-md-row
                        flex-lg-row
                        flex-xl-row
                      ">
                      <v-text-field v-model="cliente.email" class="ma-2" outlined dense label="Correo electrónico">
                      </v-text-field>
                      <v-autocomplete v-model="cliente.edad" :items="planes" item-text="Descripcion"
                        item-value="Descripcion" class="ma-2" outlined dense label="Plan de salud mascota">
                      </v-autocomplete>
                    </div>
                    <v-textarea v-model="cliente.fichaant" class="ma-2" outlined dense label="Información adicional">
                    </v-textarea>
                    <div>
                      <v-checkbox class="mx-2" v-model="cliente_actualizado" label="Datos actualizados">
                      </v-checkbox>
                    </div>
                    <!-- Botón acción actualizar cliente -->
                    <div class="d-flex ma-2 justify-end" width="100%">
                      <v-btn color="primary" :loading="activeUploadingUpdateCliente" class="text-capitalize rounded-lg"
                        @click="btnUpdateClienteById">Actualizar</v-btn>
                      <!-- <v-btn
                    color="primary"
                    outlined
                    class="text-capitalize rounded-lg ml-2"
                    @click=""
                    >Actualizar </v-btn
                  > -->
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <!-- Confirmacion creación de cliente -->
          <v-snackbar color="primary" v-model="successCreateCliente" multiLine>
            Cliente creado con éxito
          </v-snackbar>
          <v-snackbar color="primary" v-model="successUpdateCliente">
            Cliente actualizado con éxito
          </v-snackbar>
        </div>
      </div>
      <div v-else class="d-flex justify-center pa-3 ma-3">
        <div class="d-flex flex-column">
          <v-avatar tile size="300" class="d-flex justify-center">
            <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
          </v-avatar>
          <div class="d-flex justify-center">
            <h3 class="mt-3 ml-3">
              Buscar cliente para actualizar <br />
              o puede crear uno nuevo
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-center pa-3 ma-3">
      <div class="d-flex flex-column">
        <v-avatar tile size="300" class="d-flex justify-center">
          <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
        </v-avatar>
        <div class="d-flex justify-center">
          <h3 class="mt-3 ml-3">
            No tiene los permisos necesarios para <br />
            acceder a los datos
          </h3>
        </div>
      </div>
    </div>
    <v-dialog v-model="activeDialogCreateCliente" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6">
          Cliente creado con éxito
        </v-card-title>
        <v-card-text class="d-flex justify-center">

          <v-icon size="100" color="primary">mdi-check-circle-outline</v-icon>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="activeDialogCreateCliente = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      menu_customer_register: false,
      menu_pet_birth: false,
      activeMicrochip: false,

      //datos actualizados del cliente
      cliente_actualizado: true,
      cliente: {
        fichan: 0,
        nombre: "",
        amo: "",
        fono: "",
        nacimiento: "",
        raza: "Raza",
        direccion: "",
        comuna: "",
        ciudad: "",
        email: "",
        sexo: "",
        fotoactiva: "SI",
        madre: "El Bosque",
        padre: "No Registra",
        peso: "No Registra",
        altura: "",
        edad: "",
        reg: "",
        color: "",
        esp: "",
        insc: "0",
        microchip: "0",
        fichaant: "",
        clinica: "",
        drtratante: "Cliente vigente",
        peluqueria: "no registra",
        rut: "",
        id: 0,
      },
      rut: "",
      searchPet: "",
      genders: ["Macho", "Macho castrado", "Hembra", "Hembra esterilizada"],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      search: "",
      status_clientes: ["Cliente vigente", "Muerte"],
      headers: [
        { text: "Ficha", value: "Fichan" },
        { text: "Mascota", value: "Nombre" },
        { text: "Nombre", value: "Amo" },
        { align: "start", text: "Seleccionar", value: "action" },
      ],
      headersClienteUpdate: [
        { text: "Ficha", value: "Fichan" },
        { text: "Mascota", value: "Nombre" },
        { text: "Nombre", value: "Amo" },
        { text: "Rut", value: "Rut" },
        { align: "start", text: "Seleccionar", value: "action" },
      ],
      selectCustomerId: "",
      selectCustomer: false,
      dialogSelect: false,
      activeSearchCustomers: false,

      //validación de formulario
      isFormValid: false,
      errorForm: false,
      errorMessages: "",
      //microchip
      microchip: "0",

      //cargar y desactivar al crear cliente
      activeUploadingCreateCustomer: false,

      //microchip valido
      chipValid: false,
      //error rut
      error: "",

      errorMicrochip: false,

      //error inputs faltantes
      errorEmptyValues: false,
      emptyValuesList: [],

      //Error desplegado en caso de que los campos obligatorios se encuentren vacíos
      rules: {
        required: (value) => (value && Boolean(value)) || "Campo requerido",
      },
      activeShowCreateCliente: false,
      activeShowSearchClienteUpdate: false,
      searchClienteUpdate: "",
      activeShowViewCliente: false,
      activeUploadingUpdateCliente: false,
      activeDialogCreateCliente: false,
    };
  },
  watch: {
    dialogSelect(val) {
      val || this.closeDelete();
    },
    search: function (val) {
      this.searchClientes(val);
    },
    // searchClienteUpdate: function (val) {
    //   this.searchClientes(val);
    // },
    successCreateCliente: function (val) {
      if (val) {
        this.activeUploadingCreateCustomer = false;
        this.activeDialogCreateCliente = true;
        setTimeout(() => {
          this.activeDialogCreateCliente = false;

        }, 2000)
        this.cleanFormCliente();
      }
    },
    successUpdateCliente: function (val) {
      if (val) {
        this.activeUploadingUpdateCliente = false;
      }
    },
    rut: function (val) {
      this.rut = this.cleanRut(this.rut);
      let len = this.rut.length - 1;
      if (len > 3) {
        let dv = this.rut[len];
        let beforeDv = this.rut
          .substring(0, len)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        this.rut = beforeDv + "-" + dv;
      }
    },
    microchip: function (val) {
      if (val.length != 15) {
        this.errorMicrochip = true;
        this.chipValid = false;
      } else {
        this.errorMicrochip = false;
        this.chipValid = true;
        this.cliente.microchip = val;
      }
    },
  },
  methods: {
    ...mapActions("customers-module", [
      "postCliente",
      "postUpdateCliente",
      "getClientes",
      "getPlanes",
    ]),
    ...mapActions("pets-module", ["postPet"]),
    ...mapActions("medical-module", ["getAces"]),
    cleanRut(value) {
      return value.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();
    },
    validateRut() {
      let rut = this.cleanRut(this.rut);
      let digito_verificador = this.rut.substr(-1);

      rut = rut.substr(0, rut.length - 1);

      let serie = 0;
      let producto = 0;

      do {
        producto += (rut % 10) * ((serie % 6) + 2);
        serie++;
      } while ((rut = Math.floor(rut / 10)));

      let resto = producto % 11;
      let resultado = 11 - resto;

      if (resultado == 11) {
        resultado = 0;
      } else if (resultado == 10) {
        resultado = "K";
      }

      if (digito_verificador != resultado) {
        this.errorInRut();
      } else {
        this.error = "";
      }
    },
    errorInRut() {
      this.error = "RUT Inválido";
      this.$refs.rut.focus();
    },
    btnCreateCliente() {
      if (this.$refs.form.validate()) {
        if (this.cliente_actualizado) {
          this.cliente.fotoactiva = "SI";
        } else {
          this.cliente.fotoactiva = "NO";
        }

        this.activeUploadingCreateCustomer = true;
        this.cliente.rut = this.rut.replaceAll(".", "");
        this.cliente.reg = this.cliente.email;
        var date_register_set = new Date(this.cliente.clinica);
        var day = date_register_set.getDay();
        var month = date_register_set.getMonth() + 1;
        var year = date_register_set.getFullYear();
        this.cliente.clinica = day + "/" + month + "/" + year;
        this.postCliente(this.cliente);
      }
    },
    closeDelete() {
      this.dialogSelect = false;
    },
    btnSelectCustomerDialog(id) {
      this.selectCustomerId = id;
      let search = this.clientes.find(
        (item) => item.Id == this.selectCustomerId
      );

      this.cliente.amo = search.Amo;
      this.rut = search.Rut;

      this.dialogSelect = true;
    },
    btnSelectCustomer() {
      let search = this.clientes.find(
        (item) => item.Id == this.selectCustomerId
      );

      this.cliente.fono = search.Fono;
      this.cliente.amo = search.Amo;
      this.cliente.direccion = search.Direccion;
      this.cliente.comuna = search.Comuna;
      this.cliente.ciudad = search.Ciudad;
      this.cliente.email = search.Email;
      this.cliente.fotoactiva = search.Fotoactiva;
      this.cliente.padre = search.Padre;
      this.cliente.peso = search.Peso;
      this.cliente.altura = search.Altura;
      this.cliente.edad = search.Edad;
      this.cliente.reg = search.Reg;
      this.cliente.insc = search.Insc;
      this.rut = search.Rut;

      this.dialogSelect = false;
      this.selectCustomerId = "";
      this.selectCustomer = true;
      this.activeSearchCustomers = false;
    },
    btnSelectCustomerUpdate(id) {
      this.selectCustomerId = id;
      this.activeShowCreateCliente = false;

      let search = this.clientes.find(
        (item) => item.Id == this.selectCustomerId
      );

      this.cliente.fono = search.Fono;
      this.cliente.amo = search.Amo;
      this.cliente.direccion = search.Direccion;
      this.cliente.comuna = search.Comuna;
      this.cliente.ciudad = search.Ciudad;
      this.cliente.email = search.Email;
      this.cliente.fotoactiva = search.Fotoactiva;
      this.cliente.padre = search.Padre;
      this.cliente.madre = search.Madre;
      this.cliente.sexo = search.Sexo;
      this.cliente.peso = search.Peso;
      this.cliente.altura = search.Altura;
      this.cliente.edad = search.Edad;
      this.cliente.reg = search.Reg;
      this.microchip = search.Microchip;
      this.cliente.insc = search.Insc;
      this.cliente.fichan = search.Fichan;
      this.cliente.nombre = search.Nombre;
      this.cliente.clinica = search.Clinica;
      this.cliente.fichaant = search.Fichaant;

      this.cliente.color = search.Color;
      this.cliente.raza = search.Raza;
      this.cliente.genero = search.Genero;
      this.cliente.esp = search.Esp;
      this.cliente.microchip = search.Microchip;
      this.cliente.nacimiento = search.Nacimiento.substring(0, 10);
      this.cliente.id = search.Id;
      this.cliente.rut = search.Rut;
      this.rut = search.Rut;

      this.dialogSelect = false;
      this.selectCustomerId = "";
      this.selectCustomer = true;
      this.activeShowViewCliente = true;
      setTimeout(() => {
        var element = this.$refs["cardclienteupdate"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 70);
      }, 300);
    },
    searchClientes() {
      this.getClientes(this.search);
    },
    btnSearchCliente() {
      this.getClientes(this.searchClienteUpdate);
    },
    resetErrors() {
      this.errorForm = false;
      this.errorMessages = "";
    },
    btnActiveCreateCliente() {
      this.cleanFormCliente();
      this.microchip = "";
      this.activeShowSearchClienteUpdate = false;
      this.activeShowViewCliente = false;
      this.activeShowCreateCliente = true;
    },
    btnUpdateClienteById() {
      this.activeUploadingUpdateCliente = true;
      this.postUpdateCliente(this.cliente);
    },
    cleanFormCliente() {
      this.rut = "";
      this.cliente.fichan = 0;
      this.cliente.nombre = "";
      this.cliente.amo = "";
      this.cliente.fono = "";
      this.cliente.nacimiento = "";
      this.cliente.raza = "";
      this.cliente.direccion = "";
      this.cliente.comuna = "";
      this.cliente.ciudad = "";
      this.cliente.email = "";
      this.cliente.sexo = "";
      this.cliente.fotoactiva = "Si";
      this.cliente.madre = "";
      this.cliente.padre = "No Registra";
      this.cliente.peso = "No Registra";
      this.cliente.altura = "";
      this.cliente.edad = "";
      this.cliente.reg = "";
      this.cliente.color = "";
      this.cliente.esp = "";
      this.cliente.insc = "0";
      this.cliente.microchip = "";
      this.cliente.fichaant = "";
      this.cliente.clinica = "";
      this.cliente.drtratante = "Cliente vigente";
      this.cliente.peluqueria = "no registra";
      this.cliente.rut = "";
      this.cliente.id = 0;
      //limpia el form
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
  },
  computed: {
    ...mapState("customers-module", [
      "clientes",
      "razas",
      "especies",
      "loadingClientes",
      "successCreateCliente",
      "planes",
      "successUpdateCliente",
    ]),
    ...mapState("medical-module", ["aces"]),
    ...mapState("pets-module", ["pets"]),
    ...mapGetters("customers-module", ["getCustomerNameById"]),
    ...mapState("auth-module", ["licen"]),
  },
  created() {
    this.getAces();
    this.getPlanes();
  },
};
</script>
<style>
/* Botón de detalles */
.v-text-field__details {
  display: none;
}

/* Ocultar botón de headers que viene por defecto en tabla */
.v-data-table-header .v-data-table-header__icon {
  display: none;
}

/* Eliminar select Sort By desplegado en vista móvil de tabla  */
.v-data-table-header-mobile__wrapper {
  display: none;
  border-style: none;
}

.v-data-table-header-mobile {
  display: none;
}

/* Título */
h1 {
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
  font-weight: 800;

  letter-spacing: 2px;
}
</style>
